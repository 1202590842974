import styled from "styled-components"

export default styled.section`
  width: 80%;
  max-width: 1200px;
  margin: 10rem auto;
  @media (max-width: 976px) {
    width: 90%;
    margin: 5rem auto;
  }

  h3 {
    font-size: 2.4rem;
    color: var(--red);
    margin-bottom: 3.5rem;
  }

  .team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10rem 5rem;

    @media (max-width: 976px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 6rem 3rem;
    }

     @media (max-width: 600px) {
       grid-template-columns: repeat(1,1fr);
     }
  }
`