import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO, { seoDataFormat } from "../shared/seo"

const AboutUsSEO = () => {
  const {
    sanityAboutUsPage: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityAboutUsPage {
        seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default AboutUsSEO
