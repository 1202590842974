import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Member from "./member"
import TeamMemberSection from "./teamMembersStyle"

const TeamMembers = () => {
  const {
    sanityAboutUsPage: { teamMembers },
  } = useStaticQuery(graphql`
    query {
      sanityAboutUsPage {
        teamMembers {
          title
          members {
            _key
            fullName
            jobTitle
            detailDescription {
              _rawContent(resolveReferences: { maxDepth: 5 })
            }
            links {
              _key
              linkText
              url
            }
            profileImage {
              ...sanityFlightImage
            }
          }
        }
      }
    }
  `)

  return (
    <TeamMemberSection>
      <h3>{teamMembers.title}</h3>
      <div className="team-members">
        {teamMembers.members.map(member => (
          <Member key={member._key} {...member} />
        ))}
      </div>
    </TeamMemberSection>
  )
}

export default TeamMembers
