import React from "react";
import ContentBlock from "../shared/sub/contentBlock";
import MyLink from "../shared/sub/myLink";
import { Collapse } from "antd";

const { Panel } = Collapse;

import MemberStyle from "./memberStyle";
import { Image } from "@global";

interface Props {
  fullName: string;
  jobTitle: string;
  detailDescription: any;
  profileImage: any;
  links?: any;
}

const Member: React.FC<Props> = ({
  fullName,
  jobTitle,
  detailDescription,
  profileImage,
  links,
}) => {
  return (
    <MemberStyle>
      <Image data={profileImage} />
      <h3>{fullName}</h3>
      <p>{jobTitle}</p>
      <Collapse bordered={false} expandIconPosition="right">
        <Panel header="Read More" key="1">
          <ContentBlock blocks={detailDescription._rawContent} />
        </Panel>
      </Collapse>
      <div className="links">
        {links &&
          links.map(link => (
            <MyLink key={link._key} className="theme-btn inline red" url={link.url}>
              {link.linkText}
            </MyLink>
          ))}
      </div>
    </MemberStyle>
  );
};

export default Member;
