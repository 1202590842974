import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CTA from "../shared/sub/cta"
import BottomCtaStyled from "./bottomCta.style"

const BottomCTAS = () => {
  const {
    sanityAboutUsPage: {
      BottomCtas: { ctas },
    },
  } = useStaticQuery(graphql`
    query {
      sanityAboutUsPage {
        BottomCtas {
          ctas {
            _key
            reverse
            leftRatio
            rightRatio
            imageSize
            topBottomSpace
            image {
              ...sanityFlightImage
            }
            text {
              _rawContent(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    }
  `)

  return (
    <BottomCtaStyled>
      <div className="bottom-cta-container">
        {ctas.map(cta => (
          <CTA key={cta._key} {...cta} />
        ))}
      </div>
    </BottomCtaStyled>
  )
}

export default BottomCTAS
