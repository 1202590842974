import React from 'react'
import AboutUsSEO from '../components/about-us/aboutUsSEO'
import CTAS from '../components/about-us/ctas'
import TeamMembers from '../components/about-us/teamMembers'
import CalculatorBlockNav from "../components/calculators/calculatorBlockNav/"
import Partners from '../components/shared/partners'
import BottomCTAS from '../components/about-us/BottomCtas'


const AboutUs = () => {
  return (
    <div className="subpage-container">
      <AboutUsSEO />
      <CTAS />
      <Partners backgroundColor={true} />
      <TeamMembers />
      <CalculatorBlockNav />
      <BottomCTAS />
    </div>
  )
}

export default AboutUs
