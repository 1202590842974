import styled from "styled-components"

export default styled.div`
  .gatsby-image-wrapper {
    background-color: rgba(85, 30, 43, 0.2);
    border-radius: 100rem;
    margin-bottom: 2rem;
  }

  h3 {
    color: var(--red);
    font-family: regular;
    margin-bottom: 1rem;
  }

  p {
    color: var(--red);
    margin-bottom: 1.5rem;
    font-family: bold;
  }

  .ant-collapse {
    background-color: transparent;
    border-top: 1px solid rgba(85, 30, 43, 0.2);
    border-bottom: 1px solid rgba(85, 30, 43, 0.2);

    border-radius: 0;

    .ant-collapse-item {
      border-color: rgba(85, 30, 43, 0.2);
      .ant-collapse-header {
        padding: 0.7rem 0;
        color: var(--red);
        font-family: regular;
        font-size: 1.4rem;

        span {
          color: var(--red);
          top: 17px;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      p {
        font-family: regular;
      }
    }
  }

  .links {
    margin-top: 3.5rem;

    a {
      margin-bottom: 0.4rem;
    }
  }
`
